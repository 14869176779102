import _ from 'lodash';
import dayjs from 'dayjs';
import { createLogic } from 'redux-logic';
import { StatusCodes } from 'http-status-codes';
import SentryHelper from 'utils/sentry-helper';

import { setLoading, setShowingRateLimited } from 'modules/layout/duck';
import { jobTypeSelector } from 'modules/job-types/duck';
import { currentUserSelector } from 'modules/users/duck';
import { languageSelector } from 'modules/common/duck';
import { selectedProIdSelector, setCreateNewRequestError } from 'modules/requests/duck';

import {
  CREATE_REQUEST,
  CREATE_REQUEST_ERROR,
  CREATE_REQUEST_SUCCESS,
  GET_PRO_COUNT,
  GET_PRO_COUNT_ERROR,
  GET_PRO_COUNT_SUCCESS,
  GET_DUPLICATE_REQUEST,
  GET_DUPLICATE_REQUEST_ERROR,
  GET_DUPLICATE_REQUEST_SUCCESS,
} from './types';

import { createRequest, getDuplicateRequest, loadProCount } from '../service';

const createRequestLogic = createLogic({
  type: CREATE_REQUEST,

  // eslint-disable-next-line sort-keys
  processOptions: { failType: CREATE_REQUEST_ERROR },
  // eslint-disable-next-line sort-keys
  async process({ action, getState }, dispatch, done) {
    dispatch(setLoading(true));
    const state = getState();
    const currentUser = currentUserSelector(state);
    const selectedProId = selectedProIdSelector(state);
    const {
      captchaResponse,
      clientId,
      context,
      isDirectBooking,
      jobTypeFieldsVersion,
      jobTypeId,
      language,
      originJobTypeId,
      requestData,
      mode,
    } = action.payload;

    const transformedRequest = _.cloneDeep(requestData);
    // transform services type to add unitPrice and total
    const jobType = jobTypeSelector(state, { jobTypeId });
    jobType.forms.forEach((form) => {
      form.fields.forEach(({ name, options, type }) => {
        if (type === 'services') {
          options.forEach((option) => {
            transformedRequest[name].services[option.name].unitPrice = option.unitPrice;
          });

          transformedRequest[name].total = transformedRequest[name].services.reduce(
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            (total, req) => total + req.quantity * req.unitPrice,
            0
          );
        }
        if (type === 'timeslots') {
          transformedRequest[name] = _.compact(transformedRequest[name]);
          if (transformedRequest[name]) {
            transformedRequest[name] = transformedRequest[name].map((slot) => ({
              from: dayjs(slot.from).valueOf(),
              to: dayjs(slot.to).valueOf(),
            }));
          }
        }
      });
    });

    try {
      const res = await createRequest({
        captchaResponse,
        clientId: currentUser._id || clientId,
        context,
        currentUser,
        isDirectBooking,
        jobTypeFieldsVersion,
        jobTypeId,
        language,
        originJobTypeId,
        request: transformedRequest,
        mode,
        selectedProId,
      });
      dispatch(setLoading(false));
      dispatch({ payload: res, type: CREATE_REQUEST_SUCCESS });
      done();
      return res;
    } catch (err) {
      dispatch(setLoading(false));
      if (err?.response?.status === StatusCodes.TOO_MANY_REQUESTS) {
        dispatch(setShowingRateLimited(true));
      } else if (typeof err?.response?.data?.error?.code === 'string') {
        dispatch(setCreateNewRequestError(err.response.data.error.code));
      } else {
        SentryHelper.captureRequestException(err, {
          clientId: currentUser._id || clientId,
          jobTypeFieldsVersion,
          jobTypeId,
          request: transformedRequest,
        });
      }
      done();

      // eslint-disable-next-line
      console.error(err);
      return {};
    }
  },
});

const getProCountLogic = createLogic({
  process({ action, getState }) {
    const language = languageSelector(getState());
    return loadProCount(action.payload, language);
  },
  processOptions: {
    failType: GET_PRO_COUNT_ERROR,
    successType: GET_PRO_COUNT_SUCCESS,
  },
  type: GET_PRO_COUNT,
  validate({ action }, allow, reject) {
    // reject if there's no jobTypeId provided
    if (!action.payload || !action.payload.jobTypeId) {
      return reject();
    }

    return allow(action);
  },
});

const getDuplicateRequestLogic = createLogic({
  process({ action, getState }) {
    const state = getState();
    const currentUser = currentUserSelector(state);
    const { jobTypeId } = action.payload;
    return getDuplicateRequest(currentUser, jobTypeId);
  },
  processOptions: {
    failType: GET_DUPLICATE_REQUEST_ERROR,
    successType: GET_DUPLICATE_REQUEST_SUCCESS,
  },
  type: GET_DUPLICATE_REQUEST,
  validate({ action }, allow, reject) {
    const { jobTypeId } = action.payload;
    if (!jobTypeId) {
      return reject();
    }

    return allow(action);
  },
});

export default [createRequestLogic, getDuplicateRequestLogic, getProCountLogic];
