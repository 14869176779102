import {
  CREATE_REQUEST_SUCCESS,
  GET_DUPLICATE_REQUEST_SUCCESS,
  GET_DUPLICATE_REQUEST_ERROR,
  CLEAR_DUPLICATE_REQUEST,
  GET_PRO_COUNT_SUCCESS,
  GET_DUPLICATE_REQUEST,
  SET_NEW_REQUEST_MODAL_CONTEXT,
  SET_NEW_REQUEST_MODAL_PROPERTIES,
  SET_NEW_REQUEST_MODAL_PERF_TIMINGS,
  SET_CREATE_REQUEST_ERROR,
  UNSET_CREATE_REQUEST_ERROR,
} from './types';

export const initialState = {
  createRequestError: null,
  createdRequest: null,
  duplicateRequestId: null,
  isDuplicateRequestLoading: false,
  newRequestModal: {
    context: { address: null, city: null, prefecture: null },
    initialValues: {},
    isSubtitleWithoutJobType: false,
    jobTypeId: null,
    originJobTypeId: null,
    proCount: 0,
    perfTimings: {},
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_REQUEST_SUCCESS:
      return { ...state, createdRequest: action.payload.createdRequest };

    case GET_PRO_COUNT_SUCCESS:
      return {
        ...state,
        newRequestModal: { ...state.newRequestModal, proCount: action.payload.count },
      };
    case GET_DUPLICATE_REQUEST: {
      return {
        ...state,
        isDuplicateRequestLoading: true,
      };
    }

    case GET_DUPLICATE_REQUEST_ERROR: {
      return {
        ...state,
        isDuplicateRequestLoading: false,
      };
    }

    case GET_DUPLICATE_REQUEST_SUCCESS: {
      return {
        ...state,
        duplicateRequestId: action.payload.duplicateRequestId || 'not-duplicate-request',
        isDuplicateRequestLoading: false,
      };
    }

    case CLEAR_DUPLICATE_REQUEST:
      return {
        ...state,
        duplicateRequestId: null,
      };

    case SET_NEW_REQUEST_MODAL_CONTEXT:
      return { ...state, newRequestModal: { ...state.newRequestModal, context: action.payload } };

    case SET_NEW_REQUEST_MODAL_PROPERTIES: {
      return { ...state, newRequestModal: { ...state.newRequestModal, ...action.payload } };
    }

    case SET_NEW_REQUEST_MODAL_PERF_TIMINGS:
      return {
        ...state,
        newRequestModal: { ...state.newRequestModal, perfTimings: action.payload },
      };
    case SET_CREATE_REQUEST_ERROR: {
      return {
        ...state,
        createRequestError: { code: action.payload },
      };
    }
    case UNSET_CREATE_REQUEST_ERROR: {
      return {
        ...state,
        createRequestError: null,
      };
    }

    default:
      return state;
  }
};
export default reducer;
